import React, {useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Cafe from '../../assets/photos/cafe.jpg';
import Office from '../../assets/photos/office.jpg';
import Reception from '../../assets/photos/reception.jpg';
import VideoPods from '../../assets/photos/videopods.jpg';
import MeetingRooms from '../../assets/photos/meetingroom.jpg';
import BullPen from '../../assets/photos/bullpen.jpg';
import Cafeteria from '../../assets/photos/cafeteria.jpg';
import CafeteriaSeats from '../../assets/photos/cafeteriaseats.jpg';


export default function Gallery() {
    useEffect(()=> {
        window.scrollTo(0, 0)
    }, [])
    return(
        <React.Fragment>
        <div className={'gallery-container'}>
            <Container>
                <Row>
                    <Col lg={6}>
                        <h1 data-aos="fade-up" data-aos-delay="100"> Gallery </h1>
                        <br/>
                        <p data-aos="fade-up" data-aos-delay="100"> Running a startup is not about making money, it is
                            about bringing a change. We want to help you do that
                            efficiently. Our entire center runs with a similar mindset,
                            and so does our pricing.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
            <div className={'gallery-grids'}>
                <img data-aos="fade-up" data-aos-delay="100" src={Cafe} alt={''} />
                <img data-aos="fade-up" data-aos-delay="200" src={Office} alt={''} />
                <img data-aos="fade-up" data-aos-delay="100" src={Reception} alt={''} />
                <img data-aos="fade-up" data-aos-delay="200" src={VideoPods} alt={''} />
                <img data-aos="fade-up" data-aos-delay="100" src={MeetingRooms} alt={''} />
                <img data-aos="fade-up" data-aos-delay="100" src={BullPen} alt={''} />
                <img data-aos="fade-up" data-aos-delay="100" src={Cafeteria} alt={''} />
                <img data-aos="fade-up" data-aos-delay="100" src={CafeteriaSeats} alt={''} />

            </div>
        </React.Fragment>
    );
}