import React, {useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";

export default function Pricing() {


    useEffect(()=> {
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className={'pricing-container'}>
            <Container>
                <Row>
                    <Col lg={6}>
                        <h1 data-aos="fade-up" data-aos-delay="100"> Pricing </h1>
                        <br/>
                        <p data-aos="fade-up" data-aos-delay="100"> Running a startup is not about making money, it is
                            about bringing a change. We want to help you do that
                            efficiently. Our entire center runs with a similar mindset,
                            and so does our pricing.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container fluid>
                <Row>
                    <Col lg={4}>
                        <div className={'pricing-column-container'}>
                            <div data-aos="fade-up" data-aos-delay="100" className={'pricing-column-inner'}>
                                <h3> Small Company </h3>
                                <hr className={'white-border'}/>
                                <h2> Rs. 6999 per person per month plus tax. </h2>
                                <hr className={'white-border'}/>
                                <li> 5-star co-working space. </li>
                                <li> Upto 5 times access to a meeting room monthly. </li>
                                <li> Upto 5 times access to a conference room monthly. </li>
                                <li> Complimentary Cafeteria Access </li>
                                <li> Free Seminar Entries </li>
                                <li> Free Workshop Entries </li>
                                <li> Unlimited Coffee, Tea </li>
                                <li> Any 3 integrated consultancy </li>
                                <li> Storage Space </li>
                                <li> Fixed Space </li>
                            </div>
                            <br/>
                            <a href={'https://rzp.io/l/9W3kGMG'}>
                                <button data-aos="fade-up" data-aos-delay="200" className={'pricing-button'}> Book Yours Now </button>
                            </a>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div data-aos="fade-up" data-aos-delay="200" className={'pricing-column-container'}>
                            <div className={'pricing-column-inner orange'}>
                                <h3> Medium Company </h3>
                                <hr className={'white-border'}/>
                                <h2> Rs. 7999 per person per month plus tax. </h2>
                                <hr className={'white-border'}/>
                                <li> Everything included in Small Company Plan plus </li>
                                <li> Upto 7 different types of consultancy support </li>
                                <li> Discounted services with partners</li>
                                <li> 1 Cabin Access (if 4 or more people buy from same) </li>
                                <li> Upto 10 times access to a conference room in a month </li>
                                <li> Upto 10 times access to a meeting room in a month </li>
                            </div>
                            <br/>
                            <a href={'https://rzp.io/l/4RFgIRL'}>
                                <button data-aos="fade-up" data-aos-delay="200" className={'pricing-button orange'}> Book Yours Now </button>
                            </a>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div data-aos="fade-up" data-aos-delay="300" className={'pricing-column-container'}>
                            <div className={'pricing-column-inner green'}>
                                <h3> Big Company </h3>
                                <hr className={'white-border'}/>
                                <h2> Rs. 8999 per person per month plus tax. </h2>
                                <hr className={'white-border'}/>
                                <li> Every thing in a Medium Company Plan plus </li>
                                <li> Every thing in a Medium Company Plan plus </li>
                                <li> 2 Cabin Access (if 8 or more people buy from same) </li>
                                <li> All integrated consultancy support. </li>
                                <li> Unlimited access to a conference room </li>
                                <li> Unlimited access to a meeting room </li>
                            </div>
                            <br/>
                            <a href={'https://rzp.io/l/Niq5b0I'}>
                                <button data-aos="fade-up" data-aos-delay="200" className={'pricing-button green'}> Book Yours Now </button>
                            </a>                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}