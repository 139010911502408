import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

export default function Contact() {
    return(
        <div className={'contact-container'}>
            <Container>
                <Row>
                    <Col lg={12}>
                        <h1 data-aos="fade-up" data-aos-delay="100"> Contact </h1>
                        <br/>
                        <hr data-aos="fade-up" data-aos-delay="100"/>
                        <h3 data-aos="fade-up" data-aos-delay="100"> Visit </h3>
                        <hr data-aos="fade-up" data-aos-delay="100"/>
                        <p data-aos="fade-up" data-aos-delay="100"> You can come for a visit at 16th Floor, Lodha Supremus, Saki Vihar Rd, Opposite Saki Vihar MTNL Exchange, Powai, Mumbai, Maharashtra 400072. </p>
                        <p data-aos="fade-up" data-aos-delay="100"> <a href={'https://www.google.com/maps/dir//lodha+supremus+powai/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3be7c80f0f11c3ab:0x904f8c8098dfc3a?sa=X&ved=2ahUKEwimsqvozOXpAhUCIbcAHX54BtIQ9RcwDHoECBsQBQ'}> Get Directions. </a></p>
                        <hr data-aos="fade-up" data-aos-delay="200"/>
                        <h3 data-aos="fade-up" data-aos-delay="200"> Contact </h3>
                        <hr data-aos="fade-up" data-aos-delay="200"/>
                        <p data-aos="fade-up" data-aos-delay="200"><a href={'tel:+919167097030'}> Call Us +91 (91670)97030 </a></p>
                        <p data-aos="fade-up" data-aos-delay="300"> or Mail us on <a href={'mailto:hello@powaicubicles.com'}> <u> hello@powaicubicles.com </u> </a> or <a href={'mailto:goldie@powaicub.com'}> <u> goldie@powaicub.com </u> </a> </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}