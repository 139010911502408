import React from 'react';
import Cafeteria from '../../assets/photos/cafeteria.jpg';
import MeetingRoom from '../../assets/photos/meetingroom.jpg';
import Bullpen from '../../assets/photos/bullpen.jpg';
import CafeteriaSeats from '../../assets/photos/cafeteriaseats.jpg';


export default function IntroGrid() {
    return(
        <React.Fragment>
            <div className={'intro-grid-container-left'}>
                <div data-aos="fade-up"
                     data-aos-delay="300" className={'grid-item'}>
                    <p>Great companies are built on top of great motivation. Having a luxurious office with all the support you need, acts as one.</p>
                </div>
                <div data-aos="fade-up"
                     data-aos-delay="400" className={'grid-item-image'}>
                    <img alt={'In/Cub/8 Meeting Room'} src={MeetingRoom}/>
                </div>

            </div>
            <div className={'intro-grid-container-right'}>
                <div data-aos="fade-up"
                     data-aos-delay="100" className={'grid-item-image'}>
                    <img alt={'In/Cub/8 Cafeteria'} src={Cafeteria}/>
                </div>
                <div data-aos="fade-up"
                     data-aos-delay="200" className={'grid-item'}>
                    <p>Imagine a place where you could work in a 5 star office, with all the amenities by your side.</p>
                </div>
            </div>
            <div className={'intro-grid-container-left'}>
                <div data-aos="fade-up"
                     data-aos-delay="100" className={'grid-item'}> <p> Where you could have posh discussions in the posh meeting rooms, vibrant conference rooms and take break in a trendy cafeteria. </p> </div>
                <div data-aos="fade-up"
                     data-aos-delay="200" className={'grid-item-image'}>
                    <img alt={'In/Cub/8 Bullpen'} src={Bullpen}/>
                </div>
            </div>
            <div className={'intro-grid-container-right'}>
                <div data-aos="fade-up"
                     data-aos-delay="100" className={'grid-item-image'}>
                    <img alt={'In/Cub/8 Office Seats'} src={CafeteriaSeats}/>
                </div>
                <div data-aos="fade-up"
                     data-aos-delay="200" className={'grid-item'}> <p> and what if after all of this, you could use integrated services such as consultancy in design, development, fundraising, marketing right at your work place? </p> </div>
            </div>
        </React.Fragment>

    );
}