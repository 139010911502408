import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/photos/pcpllogo.png";
import {Link} from "react-router-dom";

export default function Footer() {
    return(
        <div data-aos="fade-in"
             data-aos-delay="100" className={'footer-container'}>
            <Container>
                <hr/>
                <Row>
                    <Col lg={8}>
                        <div className={'site-menu'}>
                            <Link to={'/contact'}>
                                <li> About </li>
                            </Link>
                            <Link to={'/contact'}>
                                <li> Location </li>
                            </Link>
                            <Link to={'/gallery'}>
                                <li> Gallery </li>
                            </Link>
                            <Link to={'/services'}>
                                <li> Services </li>
                            </Link>
                            <Link to={'/pricing'}>
                                <li> Pricing </li>
                            </Link>
                            <a  rel="noopener noreferrer" target={"_blank"} href={'http://webmail.powaicub.com/'}>
                                <li> Team Login </li>
                            </a>
                        </div>
                        <div className={'content'}>
                            <p>
                                An Initiative by a MSME company, Powai Cubicles Private Limited.<span style={{fontSize: '1rem', color: '#ff2400'}}> Supported, Funded & Handheld by Bassein Catholic Co-Operative Bank .</span>
                                <hr/>
                                In/Cub/8 by Powai Cubicles provides young startups and companies a co-working space that integrates various startup-level consultancies, amenities and guidance. Be a part of a community that fosters learning, growth and scaling of startups, while at the same time working out of a luxurious office.
                            </p>
                        </div>
                        <div className={'content'}>
                            Lodha Supremus, Powai 72.
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className={'logo-container'}>
                            <img className={'logo'} width={'200px'} alt={'Powai Cubicles Logo'} src={Logo}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}