import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "react-router-dom";
import MobileCTA from "./MobileCTA";

export default function Header() {
    return(
        <div className={'header-container text-center'}>
            <MobileCTA/>
            <Container fluid>
                <Row>
                    <Col lg={4}>
                        <div className={'logo-container'}>
                            <Link to={'/'}>
                                <p data-aos="fade-up"> IN/CUB/8 </p> <br/>
                            </Link>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className={'menu-container'}>
                            <div className={'menu'}>
                                <Link to={'/services'}><li data-aos="fade-up" data-aos-delay="100"> Services </li></Link>
                                <Link to={'/pricing'}><li data-aos="fade-up" data-aos-delay="200"> Pricing </li></Link>
                                <Link to={'/gallery'}><li data-aos="fade-up" data-aos-delay="300"> Gallery </li></Link>
                                <Link to={'/contact'}><li data-aos="fade-up" data-aos-delay="400"> Contact </li></Link>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div className={'call-to-action-container'}>
                            <a href={'tel:+919167097030'}>
                            <p data-aos="fade-up"
                               data-aos-delay="500"> +91 (91670)97030 </p>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}