import React, {useEffect} from 'react';
import Cafeteria from '../../assets/photos/cafeteria.jpg';
import MeetingRoom from '../../assets/photos/meetingroom.jpg';
import {Link} from "react-router-dom";


export default function ImageActionGrid() {


    useEffect(()=> {
        window.scrollTo(0, 0)
    }, [])

    return(
        <React.Fragment>
            <div className={'image-action-grid-container-left'}>

                    <div data-aos="fade-up"
                         data-aos-delay="100" className={'grid-action'}>
                        <Link to={'/services'}>
                            <div className={'grid-action-content'}>
                                <h1> Services </h1>
                                <p> Find all that is there to offer when you or your team joins In/Cub/8. </p>
                            </div>
                        </Link>
                    </div>
                <div data-aos="fade-in"
                     data-aos-delay="100" className={'grid-picture'}>
                    <img alt={'In/Cub/8 Meeting Room'} src={MeetingRoom}/>
                </div>
            </div>
            <div className={'image-action-grid-container-right'}>
                <div data-aos="fade-in"
                     data-aos-delay="100" className={'grid-picture'}>
                    <img alt={'In/Cub/8 Cafeteria'} src={Cafeteria}/>
                </div>
                <div data-aos="fade-up"
                     data-aos-delay="100" className={'grid-action'}>
                    <Link to={'/gallery'}>
                        <div className={'grid-action-content'}>
                            <h1> Gallery </h1>
                            <p> Our architects have created a vibrant, polymath focused designed offices to resonate with startups of today's age. </p>
                        </div>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
}