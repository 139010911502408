import React from 'react';
import IntroGrid from "./IntroGrid";
import LargeCTA from "./LargeCTA";
import ImageActionGrid from "./ImageActionGrid";
import BookingCTA from "../../components/BookingCTA";

export default function Home() {
    return(
        <div className={'home-container'}>
            <IntroGrid/>
            <LargeCTA/>
            <ImageActionGrid/>
            <BookingCTA/>
        </div>
    );
}