import React from 'react';
import CallIcon from '../../assets/photos/callicon.png';

export default function MobileCTA() {
    return(
        <div className={'mobile-cta-container'}>
            <a href={'tel: +919167097030'}>
                <img src={CallIcon} alt={'Call Icon'} width={'25px'} height={'25px'}/>
            </a>
        </div>
    );
}