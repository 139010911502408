import React from 'react';
import {Link} from "react-router-dom";

export default function LargeCTA() {
    return(
        <div className={'large-cta-container'}>
            <div data-aos="fade-up"
                 data-aos-delay="100" className={'inner-cta-container'}>
                <div className={'inner-content'}>
                    <h1> What if you could get all this,
                        and a lot more for 1/3rd
                        price of your traditional office?
                    </h1>
                    <Link to={'/pricing'}>
                        <p> Plans starting as low as <br/> Rs. 6999 per month. </p>
                    </Link>
                </div>
            </div>
        </div>
    );
}