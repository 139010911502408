import React, {useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Cafeteria from '../../assets/photos/cafeteria.jpg';
import Office from '../../assets/photos/meetingroom.jpg'
import OfficeSeats from '../../assets/photos/officeseats.jpg';
import CafeteriaSeats from '../../assets/photos/cafeteriaseats.jpg'
import Cafe from '../../assets/photos/cafe.jpg';
import Reception from '../../assets/photos/reception.jpg';
import VideoPods from '../../assets/photos/videopods.jpg';
import Office2 from '../../assets/photos/office.jpg';

import BookingCTA from "../../components/BookingCTA";

const coworkingServices = [
    'Humongous 14,000 sq. ft ready to move-in offices.',
    'Video conferencing rooms for teams to co-ordinate with rising remote work',
    'Meeting rooms designed as lounge rooms for you to better understand your clients',
    'Storage space for all the teams, at every fixed desk.',
    'Complimentary 5-star conference rooms to conduct meetings',
    'Multiple multi-seater meeting rooms when brain-storming alone cannot get through'
];

const integratedServices = [
    'Design Consultancy',
    'Business Development Consultancy',
    'Development Consultancy',
    'Legal Consultancy',
    'Growth Hacking Seminars',
    'HR & Talent Acquisition Consultancy',
    'Fundraising Consultancy',
    'Accounting Consultancy'
];


const amenities = [
    'Tea, Coffee & Snacks.',
    'Centralised Air Conditioning',
    'Reception',
    'Security',
    'Internet',
    'Printers & Copiers',
    'Cafeteria',
    'Weekend Beer Bar (Coming Soon!)'
];


const advantages =
    [
        'Discounted development, designing services from our partnered agencies.',
        'Low cost of scaling up as majority of the services are in-house',
        'Free all year access to various seminars conducted in our co-working space',
        'Special pricing for Recent Graduates',
        'Special pricing for IIT Graduates (Nearby Campus)',
        'Get to be a part of a community of startups',
        '50% less pricing and more features compared to any co-working space.',
        'Located in the heart of India\'s Silicon Valley, Powai'
    ];


export default function Services() {

    useEffect(()=> {
        window.scrollTo(0, 0)
    }, [])

    return(
        <React.Fragment>
            <div className={'services-container'}>
                <Container>
                    <Row>
                        <Col lg={9}>
                            <h1 data-aos="fade-up" data-aos-delay="100"> Services </h1>
                            <br/>
                            <p data-aos="fade-up" data-aos-delay="100"> Every In/Cub/8 seat comes with a ton of services, complimentary services,
                                integrated consultancies, and a range of amenities.
                            </p>
                            <p data-aos="fade-up" data-aos-delay="100">
                                We believe a startup should only focus on its core products , rest – we can take care off.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <div className={'service-title'}>
                                <h2 data-aos="fade-up" data-aos-delay="100">1. Luxurious Co Working Space. </h2>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className={'service-list-container'}>
                                {coworkingServices.map((item, i) => (
                                    <li key={i} data-aos="fade-up" data-aos-delay="100"> {item} </li>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'image-row-container'}>
                <div data-aos="fade-in" data-aos-delay="100" className={'image-item'}>
                    <img alt={'Cafeteria'} src={Cafeteria}/>
                </div>
                <div  data-aos="fade-in" data-aos-delay="100" className={'image-item'}>
                    <img alt={'MeetingRooms'} src={Office}/>
                </div>
                <div  data-aos="fade-in" data-aos-delay="100" className={'image-item'}>
                    <img alt={'Office'} src={OfficeSeats}/>
                </div>
                <div  data-aos="fade-in" data-aos-delay="100" className={'image-item'}>
                    <img alt={'Cafeteria'} src={CafeteriaSeats}/>
                </div>
            </div>
            <div className={'services-container'}>
                <Container>
                    <Row>
                        <Col lg={9}>
                            <div className={'service-title'}>
                                <h2 data-aos="fade-up" data-aos-delay="100">2. Integrated Consultancy & Subsidized Services</h2>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className={'service-list-container'}>
                                {integratedServices.map((item, i) => (
                                    <li key={i} data-aos="fade-up" data-aos-delay="100"> {item} </li>
                                ))}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={9}>
                            <div className={'service-title'}>
                                <h2 data-aos="fade-up" data-aos-delay="100">3. Amenities</h2>
                                <p data-aos="fade-up" data-aos-delay="100"> Every seat at In/Cub/8 has various amenities to take care of your work-routine. Find them below! </p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className={'service-list-container'}>
                                {amenities.map((item, i) => (
                                    <li key={i} data-aos="fade-up" data-aos-delay="100"> {item} </li>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={'image-row-container'}>
                <div data-aos="fade-in" data-aos-delay="100" className={'image-item'}>
                    <img alt={'Cafeteria'} src={Cafe}/>
                </div>
                <div  data-aos="fade-in" data-aos-delay="100" className={'image-item'}>
                    <img alt={'Cafeteria'} src={Reception}/>
                </div>
                <div  data-aos="fade-in" data-aos-delay="100" className={'image-item'}>
                    <img alt={'Bullpen'} src={VideoPods}/>
                </div>
                <div  data-aos="fade-in" data-aos-delay="100" className={'image-item'}>
                    <img alt={'Bullpen'} src={Office2}/>
                </div>
            </div>
            <div className={'services-container'}>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className={'service-title'}>
                                <h2 data-aos="fade-up" data-aos-delay="100">4. Other deals & Advantages </h2>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className={'service-list-container'}>
                                {advantages.map((item, i) => (
                                    <li key={i} data-aos="fade-up" data-aos-delay="100"> {item} </li>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <BookingCTA/>
        </React.Fragment>

    );
}