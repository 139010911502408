import React from 'react';
import ReactGA from 'react-ga';
/*
These files are required for global styling
 */
import '../styles/app.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from "../Routes";

ReactGA.initialize('UA-168437173-1');

function App() {
  return (
    <div className="App">
      <Routes/>
    </div>
  );
}

export default App;
