import React from 'react';
import Header from "./components/Header/Header";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Home from "./containers/Home/Home";
import Footer from "./components/Footer/Footer";
import Services from "./containers/Services/Services";
import Pricing from "./containers/Pricing/Pricing";
import Gallery from "./containers/Gallery/Gallery";
import Contact from "./containers/Contact/Contact";
import ReactGA from "react-ga";

export default function Routes(){
    return(
        <Router>
            <Header/>
            <Switch>
                {ReactGA.pageview(window.location.pathname + window.location.search)}
                <Route exact path={'/'}>
                    <Home/>
                </Route>
                <Route exact path={'/services'}>
                    <Services/>
                </Route>
                <Route exact path={'/pricing'}>
                    <Pricing/>
                </Route>
                <Route exact path={'/gallery'}>
                    <Gallery/>
                </Route>
                <Route exact path={'/contact'}>
                    <Contact/>
                </Route>
            </Switch>
            <Footer/>
        </Router>
    );
}