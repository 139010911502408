import React from 'react';
import {Link} from "react-router-dom";

export default function BookingCTA() {
    return(
        <div className={'booking-cta-container'}>
            <div data-aos="fade-up"
                 data-aos-delay="100" className={'booking-cta-inner'}>
                <h1>
                    All this, starting at just Rs. 6999 per month.
                </h1>
                <Link to={'/pricing'}>
                    <button>
                        Book yours now
                    </button>
                </Link>
            </div>
        </div>
    );
}